import React, { useEffect, useState } from "react";
import css from "./CartsGridBlock.module.css";
import { Content } from "antd/es/layout/layout";
import { Cart, CartProps } from "../Cart/Cart";
import axios from "axios";
export const CartsGridBlock = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get("https://ludomenchik-api.ru:8443/api/data")
      .then((response) => setData(response.data));
  }, []);

  if (!data.length) {
    return null;
  }

  return (
    <Content className={css.cartGridWrapper}>
      {data.map(
        (
          {
            imgSrc,
            textFirstBonus,
            textSecondBonus,
            textWithdraw,
            textAcceptedCountry,
            link,
            name,
          }: CartProps,
          index
        ) => (
          <Cart
            imgSrc={imgSrc}
            textFirstBonus={textFirstBonus}
            textSecondBonus={textSecondBonus}
            textWithdraw={textWithdraw}
            textAcceptedCountry={textAcceptedCountry}
            link={link}
            name={name}
            key={index}
          />
        )
      )}
    </Content>
  );
};
