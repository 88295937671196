import React, { useCallback, useEffect, useState } from "react";
import { Header } from "antd/es/layout/layout";
import {
  YoutubeIcon,
  TelegramIcon,
  TikTokIcon,
  VkIcon,
  LogoImage,
} from "../../assets";
import css from "./HeaderBlock.module.css";
import { Image } from "antd";
import Text from "antd/lib/typography/Text";
import axios from "axios";
import { IconsNames } from "./type";

export const HeaderBlock = () => {
  const [links, setLinks] = useState<IconsNames>({});

  useEffect(() => {
    axios
      .get("https://ludomenchik-api.ru:8443/api/links")
      .then((response) => setLinks(response.data));
  }, []);

  const openLink = useCallback(
    (e: React.MouseEvent<HTMLElement>, link: string) => {
      e.preventDefault();
      window.open(link, "_blank");
    },
    []
  );

  return (
    <Header className={css.container}>
      <Image src={LogoImage} preview={false} className={css.image} />
      <div className={css.blockContent}>
        <div className={css.textBlock}>
          <Text className={css.title}>Топ лицензионных онлайн казино</Text>
        </div>
        <div className={css.iconBlock}>
          <TelegramIcon
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              openLink(e, links.linkTelegram!)
            }
            className={css.image}
          />
          <VkIcon
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              openLink(e, links.linkVkLink!)
            }
            className={css.image}
          />
          <TikTokIcon
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              openLink(e, links.linkTikTok!)
            }
            className={css.image}
          />
          <YoutubeIcon
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              openLink(e, links.linkYouTube!)
            }
            className={css.image}
          />
        </div>
      </div>
    </Header>
  );
};
