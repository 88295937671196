import React, { useCallback } from "react";
import css from "./Cart.module.css";
import { Button, Image } from "antd";
import Text from "antd/lib/typography/Text";

export type CartProps = {
  imgSrc?: string;
  textFirstBonus?: string;
  textSecondBonus?: string;
  textWithdraw?: string;
  textAcceptedCountry?: string;
  link?: string;
  name?: string;
};
export const Cart = ({
  imgSrc,
  textFirstBonus,
  textSecondBonus,
  textWithdraw,
  textAcceptedCountry,
  link,
  name,
}: CartProps) => {
  const openLink = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      window.open(link, "_blank");
    },
    [link]
  );

  return (
    <div className={css.cartWrapper}>
      <div className={css.titleContainer} onClick={openLink}>
        <Text className={css.title}>{name}</Text>
      </div>
      <Image
        onClick={openLink}
        preview={false}
        className={css.image}
        src={imgSrc}
      />
      <ul>
        <li>
          <Text className={css.text}>{textFirstBonus}</Text>
        </li>
        <li>
          <Text className={css.text}>{textSecondBonus}</Text>
        </li>
        <li>
          <Text className={css.text}>{textWithdraw}</Text>
        </li>
        <li>
          <Text className={css.text}>{textAcceptedCountry}</Text>
        </li>
      </ul>
      <Button className={css.button} onClick={openLink}>
        <Text className={css.buttonText}>Получить Бонус</Text>
      </Button>
    </div>
  );
};
