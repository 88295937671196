import React from "react";
import "./App.css";
import { Layout } from "antd";
import { HeaderBlock } from "../components/HeaderBlock/HeaderBlock";
import { CartsGridBlock } from "../components/CartsGrid/CartsGridBlock";

function App() {
  return (
    <Layout className="App">
      <HeaderBlock />
      <CartsGridBlock />
    </Layout>
  );
}

export default App;
