import { ReactComponent as Youtube } from "./svg/icons8-youtube.svg";
import { ReactComponent as Telegram } from "./svg/icons8-telegram-app.svg";
import { ReactComponent as TikTok } from "./svg/icons8-tiktok.svg";
import { ReactComponent as Vk } from "./svg/icons8-vkontakte.svg";

import Logo from "./logo.png";

export const YoutubeIcon = Youtube;
export const TelegramIcon = Telegram;
export const TikTokIcon = TikTok;
export const VkIcon = Vk;

export const LogoImage = Logo;
